// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  trackingId : "UA-136336878-4",
  api : "https://ports-service.esp-staging.vmware-aws.com/",
  ssoURL : "https://myvmware-stg.workspaceair.com/SAAS/API/1.0/GET/apps/launch/app/aaa20196-48b0-4c54-b9aa-b1ba7ae3939f",
  clientId : "aed572517d2db4d2bb7fc504796972f4",
  ipfURL: "feedback.esp-staging.vmware-aws.com",
  state: "t3jbMJNi",
//   authApiUrl: "https://auth.esp-staging.vmware-aws.com/api/auth/v1",
  authApiUrl: "https://broadcom.oktapreview.com/oauth2/default/v1",
  oktaClientId: "0oa24uep5dgIc67WW0h8",
  oktaClientPassword: "xPtrQT-Iyzj549x-FIPLEdmqvRwNrURB3WZUzRzU8OfIuMIm8x3TTbOC__LUHMlg",
  oktaCodeChallenge: "CNEVLWahKdih6qzoM-5E7URKGNQ4kfbAn5GpT1_KWUA",
  oktaCodeVerifier: "pfDKD4JGyueJdwvVGOUMQ5vz0OO5SZW3TAIHJMNX18-wkslVxC3A2BDm3_w",
  espClientId: "pnp-client",
  redirectUri: "/auth",
  empAdUrl: "https://employee-ad.esp-staging.eng.vmware.com/",
  whitneyUrl: "https://wp-configuration.esp.vmware.com",
  localizationFilesPath: "https://wp-configuration.esp.vmware.com/configurationapi/v1/ports/labels/supportedvalues?locale="
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
