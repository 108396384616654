export const environment = {
  production: false,
  trackingId: "UA-136336878-4",
  api: "https://ports-service.esp-staging.vmware-aws.com/",
  ssoURL: "https://myvmware-stg.workspaceair.com/SAAS/API/1.0/GET/apps/launch/app/aaa20196-48b0-4c54-b9aa-b1ba7ae3939f",
  clientId : "aed572517d2db4d2bb7fc504796972f4",
  ipfURL: "feedback.esp-staging.vmware-aws.com",
  state: "t3jbMJNi",
  authApiUrl: "https://auth.esp-staging.vmware-aws.com/api/auth/v1",
  espClientId: "pnp-client",
  redirectUri: "/auth",
  empAdUrl: "https://employee-ad.esp-staging.eng.vmware.com",
  whitneyUrl: "https://wp-configuration.esp.vmware.com"
};