export const environment = {
    production: false,
    trackingId : "UA-136336878-2",
    api : "https://ports-service.esp-staging.vmware-aws.com/",
    ssoURL : "https://myvmware-stg.workspaceair.com/SAAS/API/1.0/GET/apps/launch/app/f2e62a99-c45d-4c19-8d36-3b2690c2f775",
    clientId : "aed572517d2db4d2bb7fc504796972f4",
    ipfURL: "feedback.esp-staging.vmware-aws.com",
    state: "t3jbMJNi",
//     authApiUrl: "https://auth.esp-staging.vmware-aws.com/api/auth/v1",
    authApiUrl: "https://broadcom.okta.com/oauth2/default/v1",
    oktaClientId: "0oax4jp5vew1IRP8U2p7",
    oktaClientPassword: "7fcd38Qf4wBM_GJb1SHW5JraDyNPbEeSHW3Jnlsa4FpmSEG2ZJqAqNFMV6CIDCC7",
    oktaCodeChallenge: "2LWVsgxqjLD71xG1XpIxz7NRtloU0OsuvYGPJUvvlxU",
    oktaCodeVerifier: "wlikgZytmlN7lppH-S86QBvogvIHmJzChPbK4qb4IYgny84H2AgdMOUvd5Pa",
    espClientId: "pnp-client",
    redirectUri: "/auth",
    empAdUrl: "https://employee-ad.esp-staging.eng.vmware.com/",
    // localizationFilesPath: "./assets/i18n/",
    localizationFilesPath: "https://wp-configuration.esp.vmware.com/configurationapi/v1/ports/labels/supportedvalues?locale=",
    whitneyUrl: "https://wp-configuration.esp.vmware.com"
  };
