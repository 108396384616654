import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Translation } from "../models/translation";
import { EnvironmentsService } from "./environment.service";

@Injectable({
    providedIn: 'root',
  })


  export class WhitneyService {
    baseUrl: string;
    trans: Observable<any>;

    constructor(private http: HttpClient, private envService: EnvironmentsService) {
      }

      public getTranslation(): Observable<any> {
        this.trans = this.http.get<any>(this.envService.config.whitneyUrl+ `/configurationapi/v1/ports/labels/supportedvalues?locale=${localStorage.getItem('pnp-lang') ? localStorage.getItem('pnp-lang') : 'en'}`)
            return this.trans;
      }
  }